import { LocationsGroups, LocationsGroupsFormFields, State } from '@/views/administration/views/administration-locations-groups/administration-locations-groups.types';
import { RootState }                                         from '@/store/store.types';
import { GetterTree }             from 'vuex';
import { primaryColor } from '@/helpers/variables';

const getters: GetterTree<State, RootState> = {
  tableData(state): LocationsGroups[] {
    if(state.locationsGroups) {
      return state.locationsGroups;
    }
    return []
  },
  selectedLocationGroup(state): LocationsGroupsFormFields {
    if(state.selectedLocationGroup) {
      return state.selectedLocationGroup;
    }
    return {
      name: '',
      color: primaryColor,
    }
  },
  locationsGroupsToReplace(state) {
    return state.locationsGroupsToReplace.map(locationGroup => ({ label: locationGroup.name, uuid: locationGroup.uuid }))
  }
};

export default getters;
