import { Module }    from 'vuex';
import { State }     from '@/views/administration/views/administration-locations-groups/administration-locations-groups.types';
import { RootState } from '@/store/store.types';
import mutations     from '@/views/administration/views/administration-locations-groups/store/administration-locations-groups.mutation';
import actions       from '@/views/administration/views/administration-locations-groups/store/administration-locations-groups.actions';
import getters       from '@/views/administration/views/administration-locations-groups/store/administration-locations-groups.getters';
import { state }     from '@/views/administration/views/administration-locations-groups/store/administration-locations-groups.state';

const store: Module<State, RootState> = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default store;
