import { MutationTree }                                   from 'vuex';
import { LocationsGroups, LocationsGroupsParams, State, } from '@/views/administration/views/administration-locations-groups/administration-locations-groups.types';
import { SidePanelState } from '@/types';

const mutations: MutationTree<State> = {
  setLocationsGroups(state, locationsGroups: LocationsGroups[]): void {
    state.locationsGroups = locationsGroups;
  },
  setSelectedLocationGroup(state, locationGroup: LocationsGroups): void {
    state.selectedLocationGroup = locationGroup;
  },
  setLocationsGroupsParams(state, payload: LocationsGroupsParams): void {
    state.params = payload;
  },
  clearSelectedLocationsGroup(state): void {
    state.selectedLocationGroup = null;
  },
  setSidePanelState(state, payload: SidePanelState): void {
    state.sidePanelState = payload
  },
  setLocationsGroupsToReplace(state, payload: LocationsGroups[]): void {
    state.locationsGroupsToReplace = payload
  }
};

export default mutations;
