<template>
  <div class="c-form-input min-w-3 flex flex-col mt-f5"
       :title="error ? useGlobalTranslations(`errors.${error}`) : ''">
    <label class="text-14 pt-f5 leading-5 text-gray-600 cursor-pointer">{{ label }}
      <span v-if="required">*</span>
      <c-form-error-icon :error="error" />
      <div class="min-w-full text-black text-12 focus:outline-none mt-f8">
        <el-color-picker v-bind="$attrs" />
      </div>
    </label>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useGlobalTranslations }                                       from '@/hooks/use-translations';
import CFormErrorIcon                             from '@/components/common/form/form-error-icon/form-error-icon.component.vue';

export default defineComponent({
  name: 'CColorPicker',
  components: { CFormErrorIcon },
  props: {
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null,
    },
    focus: {
      type: Boolean,
      default: false
    }
  },
  setup() {

    return{
      useGlobalTranslations
    }
  }
});
</script>
