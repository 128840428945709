import { State } from '@/views/administration/views/administration-locations-groups/administration-locations-groups.types';
import { SidePanelState } from '@/types';

export const state: State = {
  locationsGroups: null,
  selectedLocationGroup: null,
  locationsGroupsToReplace: [],
  sidePanelState: SidePanelState.EDIT_ENTITY,
  params: {
    searchQuery: '',
    'sortBy[0].field': 'name',
    'sortBy[0].direction': 'ASC',
  },
}