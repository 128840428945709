import { ActionContext, ActionTree }                                                            from 'vuex';
import { RootState }                                                                            from '@/store/store.types';
/*eslint-disable */
import { LocationsGroups, State, }                                                              from "@/views/administration/views/administration-locations-groups/administration-locations-groups.types";
/*eslint-enable */
import { createEntity, deleteSingleEntity, getEntitiesToReplace, getSingleEntity, getTableData, replaceEntity, updateSingleEntity, } from '@/views/administration/administration.service';
import i18n                                                                                     from '@/i18n';
import { GLOBAL }                                                                               from '@/helpers/variables';
import { Notification }                                                                         from '@/components/notification/notification';
import { DataWithPagination, SidePanelState } from '@/types';
import { AdministrationEndpoints, AdministrationPagination, DeleteEntityResponse } from '@/views/administration/administration.types';


const actions: ActionTree<State, RootState> = {
  async getLocationsGroups(
    context: ActionContext<State, RootState>
  ): Promise<void> {
    try {
      const { currentPage, pageSize } = context.rootState.pagination[
        AdministrationPagination.LOCATIONS_GROUPS
      ];
      const params = context.state.params;

      const allParams = {
        ...params,
        page: currentPage,
        size: pageSize,
      };

      const {
        data,
      }: { data: DataWithPagination<LocationsGroups> } = await getTableData(
        AdministrationEndpoints.LOCATIONS_GROUPS,
        allParams
      );

      context.commit('setLocationsGroups', data.content);
      context.commit(
        'updatePaginationElements',
        {
          totalElements: data.totalElements,
          name: AdministrationPagination.LOCATIONS_GROUPS,
        },
        { root: true }
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async getLocationGroupEntity(
    context: ActionContext<State, RootState>,
    uuid: string
  ): Promise<void> {
    try {
      const { data }: { data: LocationsGroups } = await getSingleEntity(
        AdministrationEndpoints.LOCATIONS_GROUPS,
        uuid
      );
      context.commit('setSelectedLocationGroup', data);
    } catch (error) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${error.data}`)
      );
      throw error.data;
    }
  },
  async addLocationGroup(
    context: ActionContext<State, RootState>,
    { name, color }: Omit<LocationsGroups, 'uuid'>
  ): Promise<void> {
    try {
      await createEntity( AdministrationEndpoints.LOCATIONS_GROUPS, { name, color });
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('locationsGroups.notifications.success')
      );
    } catch (error) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${error.data}`)
      );
      throw error.data;
    }
  },
  async deleteLocationGroup(
    { dispatch },
    locationGroupUuid: string
  ): Promise<void> {
    try {
      await deleteSingleEntity( AdministrationEndpoints.LOCATIONS_GROUPS, locationGroupUuid);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('locationsGroups.notifications.delete')
      );
    } catch (error) {
      error.data.errorCode === DeleteEntityResponse.DELETE_RESTRICT
        ? dispatch('getLocationsGroupsToReplace', locationGroupUuid)
        : Notification.error(
          i18n.global.t(`${GLOBAL}.errors.error`),
          i18n.global.t(`${GLOBAL}.errors.${error.data}`)
        );
      throw error.data;
    }
  },
  async updateLocationGroup(
    context: ActionContext<State, RootState>,
    { name, color, uuid }: LocationsGroups
  ): Promise<void> {
    try {
      await updateSingleEntity(AdministrationEndpoints.LOCATIONS_GROUPS, uuid, { name, color });
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('locationsGroups.notifications.success')
      );
    } catch (error) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${error.data}`)
      );
      throw error.data;
    }
  },
  async getLocationsGroupsToReplace({ commit }, locationsGroupsUuid: string) {
    try {
      const { data } = await getEntitiesToReplace(AdministrationEndpoints.LOCATIONS_GROUPS, locationsGroupsUuid)
      commit('setLocationsGroupsToReplace', data)
      commit('setSidePanelState', SidePanelState.DELETE_ENTITY)
    }
    catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async replaceLocationGroup(context, { locationGroupUuid, replaceToLocationGroupUuid }: { locationGroupUuid: string, replaceToLocationGroupUuid: string }) {
    try {
      await replaceEntity(AdministrationEndpoints.LOCATIONS_GROUPS, locationGroupUuid, replaceToLocationGroupUuid)
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('locationsGroups.notifications.replace')
      )
    }
    catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  }
};

export default actions;
