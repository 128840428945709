
import useVuelidate, { ValidationArgs }                               from '@vuelidate/core';
import { helpers, required }                                          from '@vuelidate/validators';
import { computed, ComputedRef, defineComponent, PropType, reactive } from 'vue';
import CFormInput                                                     from '@/components/common/form/form-input/form-input.component.vue';
import CColorPicker                                                   from '@/components/common/color-picker/color-picker.component.vue';
import { LocationsGroupsFormFields, LocationsGroupsFormRules }        from '@/views/administration/views/administration-locations-groups/administration-locations-groups.types';
import { hexColorRegEX }                                              from '@/components/common/table/use-validate-table';
import { FormMode }                                                   from '@/views/administration/administration.types';
import { useTranslations }                                            from '@/hooks/use-translations';
import { useGlobals }                                                 from '@/hooks/use-globals';
import CAdministrationFormButtons                                     from '@/views/administration/components/administration-form-buttons/administration-form-buttons.component.vue';
import { Notification }                                               from '@/components/notification/notification';
import i18n                                                           from '@/i18n';
import { GLOBAL }                                                     from '@/helpers/variables';


export default defineComponent({
  name: 'CFormGroups',
  components: { CAdministrationFormButtons, CFormInput, CColorPicker },
  props: {
    formMode: {
      type: String as PropType<FormMode>,
      default: FormMode.ADD,
    },
  },
  emits: ['close-panel'],
  setup(props, { emit }) {
    const { store } = useGlobals();
    const rules: LocationsGroupsFormRules = {
      name: { required },
      color: { required, hex: helpers.regex(hexColorRegEX) },
    };

    const formFields: ComputedRef<LocationsGroupsFormFields> = computed(() => store.getters['views/locationsGroups/selectedLocationGroup']);
    const state = reactive(formFields.value);

    const v$ = useVuelidate((rules as unknown) as ValidationArgs, state as any);

    const submitGroupsForm = async (closePanel: boolean): Promise<void> => {
      v$.value.$touch();
      if (!v$.value.$error) {
        try {
          switch (props.formMode) {
            case FormMode.ADD:
              await store.dispatch('views/locationsGroups/addLocationGroup', { name: state.name, color: state.color });
              await store.dispatch('views/locationsGroups/getLocationsGroups');
              break;
            case FormMode.EDIT:
              await store.dispatch('views/locationsGroups/updateLocationGroup', {
                name: state.name,
                color: state.color,
                uuid: state.uuid
              });
              await store.dispatch('views/locationsGroups/getLocationsGroups');
              break;
            default:
              break;
          }
          v$.value.$reset();
          formFields.value.name = '';
          formFields.value.color = '';
          if (closePanel) {
            emit('close-panel');
          }
        } catch (e) {
          throw Error(e);
        }
      } else {
        Notification.warning(
          i18n.global.t(`${GLOBAL}.warning.warning`),
          i18n.global.t(`${GLOBAL}.warning.validation`),
        );
      }
    };

    const removeLocationGroup = async (): Promise<void> => {
      await store.dispatch('views/locationsGroups/deleteLocationGroup', state.uuid);
      await store.dispatch('views/locationsGroups/getLocationsGroups');
      emit('close-panel');
    };

    return {
      submitGroupsForm,
      v$,
      state,
      removeLocationGroup,
      useTranslations,
      FormMode,
    };
  },
});
