
import { computed, ComputedRef, defineComponent, Ref, ref } from 'vue';
import { useTranslations, useGlobalTranslations }                            from '@/hooks/use-translations';
import { columnTypes, TableColumn, TableRow }               from '@/components/common/table/table.types';
import { AdministrationParams, FilterTypes, SearchbarSelectItem, SidePanelState } from '@/types';
import CAdministrationTemplate                              from '@/views/administration/components/administration-template/administration-template.component.vue';
import CSidePanel                                           from '@/components/common/side-panel/side-panel.component.vue';
import CFormGroups                                          from '@/views/administration/views/administration-locations-groups/components/locations-groups-form/locations-groups-form.vue';
import { AdministrationPagination, FormMode }               from '@/views/administration/administration.types';
import { useGlobals }                                       from '@/hooks/use-globals';
import CDeleteConfirmation
  from '@/views/administration/components/delete-confirmation/delete-confirmation.component.vue';
import { LocationsGroups } from '@/views/administration/views/administration-locations-groups/administration-locations-groups.types';

export default defineComponent({
  name: 'CLocationsGroups',
  components: { CDeleteConfirmation, CFormGroups, CSidePanel, CAdministrationTemplate },
  setup() {
    const { store, i18n } = useGlobals();
    const locationGroupsToReplace: ComputedRef<SearchbarSelectItem[]> = computed(() => store.getters[ 'views/locationsGroups/locationsGroupsToReplace' ]);
    const sidePanelState: ComputedRef<SidePanelState> = computed(() => store.state.views.locationsGroups.sidePanelState)
    const selectedLocationGroup: ComputedRef<LocationsGroups> = computed(() => store.state.views.locationsGroups.selectedLocationGroup);
    const formMode: Ref<FormMode> = ref(FormMode.ADD);
    const isSidePanelOpen: Ref<boolean> = ref(false);
    const sidePanelTitle: Ref<string> = ref('');
    const columns: TableColumn[] = [
      { name: 'name', type: columnTypes.TEXT, filter: FilterTypes.SORT },
      { name: 'color', type: columnTypes.COLOR },
    ];

    const rows: ComputedRef<TableRow[]> = computed(() => store.getters[ 'views/locationsGroups/tableData' ]);
    const params: ComputedRef<Partial<AdministrationParams>> = computed(() => store.state.views.locationsGroups.params);

    store.commit('views/locationsGroups/setLocationsGroupsParams', []);
    store.dispatch('views/locationsGroups/getLocationsGroups');

    const closeSidePanel = (): void => {
      store.commit('views/locationsGroups/setSidePanelState', SidePanelState.EDIT_ENTITY);
      store.commit('views/locationsGroups/clearSelectedLocationsGroup');
      isSidePanelOpen.value = false;
    };

    const openAddNewLocationsGroup = (): void => {
      formMode.value = FormMode.ADD;
      isSidePanelOpen.value = true;
      sidePanelTitle.value = i18n.t('locationsGroups.sidePanel.addNew');
    };

    const openEditLocationsGroup = async (uuid: string | undefined): Promise<void> => {
      isSidePanelOpen.value = false;
      await store.dispatch('views/locationsGroups/getLocationGroupEntity', uuid);
      formMode.value = FormMode.EDIT;
      isSidePanelOpen.value = true;
      sidePanelTitle.value = i18n.t('locationsGroups.sidePanel.edit');
    };

    const updateParams = (updatedParams: Partial<AdministrationParams>): void => {
      store.commit('views/locationsGroups/setLocationsGroupsParams', updatedParams);
      store.dispatch('views/locationsGroups/getLocationsGroups');
    };

    const goBackToEditForm = (): void => {
      store.commit('views/locationsGroups/setSidePanelState', SidePanelState.EDIT_ENTITY)
    };

    const replaceLocationGroupAndDelete = async (locationGroupUuidToReplace: string): Promise<void> => {
      await store.dispatch('views/locationsGroups/replaceLocationGroup', { locationGroupUuid: selectedLocationGroup.value.uuid, replaceToLocationGroupUuid: locationGroupUuidToReplace })
      store.dispatch('views/locationsGroups/getLocationsGroups')
      closeSidePanel()
    };


    return {
      columns,
      rows,
      params,
      isSidePanelOpen,
      formMode,
      sidePanelTitle,
      sidePanelState,
      locationGroupsToReplace,
      replaceLocationGroupAndDelete,
      goBackToEditForm,
      openAddNewLocationsGroup,
      openEditLocationsGroup,
      closeSidePanel,
      updateParams,
      useGlobalTranslations,
      useTranslations,
      FormMode,
      SidePanelState,
      AdministrationPagination,
    };
  },
});
